import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";
import "prismjs/themes/prism-okaidia.css"
import "../styles/prism.css";
import PrevNext from "../components/prevNext";


// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  helmet,
  main,
  pageContext,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="mx-4">
      {helmet || ""}
      <div className="container-1">
          <div className="my-12">
            <div className="mb-8">
            <PreviewCompatibleImage imageInfo={main.image1} />
            </div>
              <div className="mb-8">
                <h1 className="mb-6 font-bold text-3xl text-text-primary">
                  {title}
                </h1>
                <span className="block text-sm text-gray-400 pb-2 mb-0" style={{textDecoration:`none`}}>{date}</span>
                {tags && tags.length ? (
              <div>
                  {tags.map((tag) => (                      
                      <Link to={`/tags/${kebabCase(tag)}/`}>
                          <span className="tag-outlined text-sm">{tag}</span>
                      </Link>
                  ))}
              </div>
            ) : null}
              </div> 
            <PostContent content={content} />
            {pageContext ? (
              <PrevNext pageContext={pageContext} />
            ): null}
          </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  main: PropTypes.shape({
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),    
  }),
  pageContext: PropTypes.object,
};

const BlogPost = ({ data, pageContext}) => {
  const { markdownRemark: post } = data;
  const { siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | shintakoji.xyz">
            <html lang="ja" />
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:title"
              content={`${post.frontmatter.title}`}
            />
            <meta 
            property="og:url"
            content={`${siteUrl}${post.fields.slug}`} 
            />
            <meta 
            property="og:image"
            content={`${siteUrl}${post.frontmatter.main.image1.image.childImageSharp.gatsbyImageData}`} 
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        main={post.frontmatter.main}
        pageContext={pageContext}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        description
        tags
        main {
          image1 {
            alt
            image {
              childImageSharp {
                fixed {
                  src
                }
                gatsbyImageData(
                  width: 620
                  height: 300
                )
              }
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
