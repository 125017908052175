import React from "react"
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const prevNext = ({ pageContext }) => {
  const { next, previous } = pageContext;
      return (
        <div className="md:flex mt-8 prevnext">
          { previous.frontmatter.date == null || previous == null ? null :              
               <div className="md:w-1/2 md:flex-none p-3">                 
                <span>次の記事</span>
                 <div className="leading-none">
                  <Link to={`${previous.fields.slug}`} className="flex items-stretch">
                    {previous.frontmatter.featuredimage ? (
                      <div className="MuiBox-root">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: previous.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${previous.frontmatter.title}`,
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="flex-grow flex-shrink p-4">
                    <span className="text-xs">
                    {previous.frontmatter.title}
                    </span>
                   </div>
                  </Link>
                 </div>
               </div>
              }
          {  next ==  null ? null :
             <div className="md:w-1/2 md:flex-none p-3">
                <span>前の記事</span>
                <div className="leading-none">
                 <Link to={`${next.fields.slug}`} className="flex items-stretch">
                  {next.frontmatter.featuredimage ? (
                    <div className="MuiBox-root">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: next.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${next.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="flex-grow flex-shrink p-4">
                  <span className="text-xs">
                  {next.frontmatter.title}
                  </span>
                 </div>
                </Link>
             </div>
            </div>
            } 
        </div>
        )
 }

 export default prevNext;